body{
  font-family: 'Poppins', sans-serif !important;

}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navbar .navbar-toggler{
  padding-right:40px!important;
  display:none;
}
.App-link {
  color: #09d3ac;
}

ul.list {
  list-style: none;
  padding: 0;
}
ul.list li {
  border-bottom: 1px solid #ccc;
}
.MuiButton-containedPrimary{
  background:#ff0000 !important;
}
*{
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}